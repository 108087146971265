import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import Modal from "react-modal";
import "./App.css";
import worldAtlas from "./assets/geojson/countries-110m.json";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    textAlign: "center",
  },
  overlay: { zIndex: 1000 },
};

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [countryName, setCountryName] = useState(null);
  const [projection, setProjection] = useState("geoOrthographic");

  const openModal = (name) => {
    setCountryName(name);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <select
          value={projection}
          onChange={(e) => setProjection(e.target.value)}
          className="projection-dropdown"
        >
          <option value="geoEqualEarth">Equal Earth</option>
          <option value="geoAlbers">Albers</option>
          <option value="geoAlbersUsa">Albers USA</option>
          <option value="geoAzimuthalEqualArea">Azimuthal Equal Area</option>
          <option value="geoAzimuthalEquidistant">Azimuthal Equidistant</option>
          <option value="geoOrthographic">Orthographic</option>
          <option value="geoConicConformal">Conic Conformal</option>
          <option value="geoConicEqualArea">Conic Equal Area</option>
          <option value="geoConicEquidistant">Conic Equidistant</option>
          <option value="geoStereographic">Stereographic</option>
          <option value="geoMercator">Mercator</option>
          <option value="geoTransverseMercator">Transverse Mercator</option>
        </select>
        <div style={{ width: "80vw", height: "80vh" }}>
          <ComposableMap projection={projection}>
            <svg style={{ position: "absolute" }} width="100%" height="100%">
              <defs>
                <pattern
                  id="Ocean_Texture"
                  patternUnits="userSpaceOnUse"
                  width="100%"
                  height="100%"
                >
                  <image
                    href="assets/imgs/Ocean.png"
                    x="0"
                    y="0"
                    width="100vh"
                    height="100vh"
                  />
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#Ocean_Texture)" />
            </svg>
            <ZoomableGroup center={[0, 0]} zoom={1}>
              <Geographies geography={worldAtlas}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const property_id = geo.properties.name.replaceAll(
                      " ",
                      "_"
                    );
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={`url(#${property_id}_Texture)`}
                        onClick={() => openModal(geo.properties.name)}
                        style={{
                          default: { outline: "none" },
                          pressed: { outline: "none" },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <svg>
            <defs>
              {worldAtlas.objects.countries.geometries.map((geo) => {
                const property_id = geo.properties.name.replaceAll(" ", "_");
                const url_id = geo.properties.name.replaceAll(" ", "%20");
                return (
                  <pattern
                    id={`${property_id}_Texture`}
                    patternUnits="objectBoundingBox"
                    patternContentUnits="objectBoundingBox"
                    x="0"
                    y="0"
                    width="1"
                    height="1"
                  >
                    <image
                      href={`assets/imgs/${url_id}.jpg`}
                      x="0"
                      y="0"
                      width="1"
                      height="1"
                      preserveAspectRatio="xMidYMid slice"
                    />
                  </pattern>
                );
              })}
            </defs>
          </svg>
        </div>
      </header>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Country Modal"
      >
        <h2>{countryName}</h2>
        {countryName && (
          <img
            src={`assets/imgs/${countryName.replaceAll(" ", "%20")}.jpg`}
            alt="Country"
            style={{ width: "100%", height: "auto" }} // set maximum width of image
          />
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
}

export default App;
